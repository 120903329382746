<template lang="pug">
    el-dialog( title="Tip zi libera" :visible.sync="showPopup" class="my-dialog-class" )
        el-form( label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" )
            el-row( :gutter="15" )
                el-col( :span="6" )
                    el-form-item( label="Nume" prop="Nume"  :required="true" )
                        el-input( v-model="selectedObject.Nume" )
                
                el-col( :span="4" )
                    el-form-item( label="Prescurtare" prop="Prescurtare"  :required="true" )
                        el-input( v-model="selectedObject.Prescurtare" )
                
                el-col( :span="4" )
                    el-form-item( label="Culoare" prop="Culoare"  :required="true" )
                        el-color-picker( v-model="selectedObject.Culoare" )

                el-col( :span="4" )
                    el-form-item( label="Tip Default" prop="Principala"  )
                        el-checkbox( v-model="selectedObject.Principala" true-label='1' false-label='0' ) Tip concediu default
                
                el-col( :span="4" )
                    el-form-item( label="Tip Absenta" prop="Absenta"  )
                        el-tooltip(content='ex: folosit la absent nemotivat')
                            el-checkbox( v-model="selectedObject.Absenta" true-label='1' false-label='0' ) Tip Absenta
            el-row( :gutter="15" )
                el-col( :span="12" )
                    el-form-item( label="Adauga ore la raport" prop="AdaugaOreLaRaport"  )
                        el-tooltip(content='Adauga ca ore lucrate la raport')
                            el-checkbox( v-model="selectedObject.AdaugaOreLaRaport" true-label='1' false-label='0' ) Adauga ore la raport ( ex: delegatii )
                el-col( :span="12" )
                    el-form-item( label="Adauga bonuri de masa" prop="AreBonDeMasa"  )
                        el-tooltip(content='Adauga bonuri de masa la raport')
                            el-checkbox( v-model="selectedObject.AreBonDeMasa" true-label='1' false-label='0' ) Adauga bonuri de masa la raport
                

        span( slot="footer" class="dialog-footer" )
            el-button( @click="showPopup=false" ) Renunta
            el-button( type="primary" @click="save") Salveaza
</template>

<script>
import settings from "@/backend/LocalSettings";
import BaseComponent from '@/pages/BaseComponent';

export default {
  name: "tipuri-zile-libere-dialog",
  extends: BaseComponent,
  data () {
    return { 
      showPopup: false,
      mode: 'add',
      selectedObject: { Nume: "" },
      rules: {
          Nume: [ { required: true, message: "Numele este obligatoriu" } ],
          Prescurtare:  [ { required: true, message: "Prescurtarea este obligatorie" } ],
          Culoare:  [ { required: true, message: "Culoarea este obligatorie" } ],
      }
    }
  },
  methods: {
     show_me: async function( id ) {
         this.showPopup        = true;
        if( id == null ) 
        {
            this.mode                = "add";
            this.selectedObject.Nume = "";
        }
        else
        {
            this.mode             = "edit";
            var            result = await this.post("get-tipuri-zile-libere", { id: id } );
            this.selectedObject   = result.Item;
        }
    },
    save: async function() {
        this.$refs['my-form'].validate( async(valid) => {
          if (valid) 
          {
            await this.post("save-tipuri-zile-libere", { mode: this.mode, object: this.selectedObject } );
            this.showPopup = false;
            this.$emit("save");
          }
        });
    }
  },
  mounted: function() {
  }
};
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }
    
</style>